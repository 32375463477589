import $ from 'jquery';
window.$ = $;

const validateCNPJ = (value) => {
  let cnpj        = value.replace(/[^0-9]/g, ''),
      len         = cnpj.length - 2,
      numbers     = cnpj.substring(0,len),
      digits      = cnpj.substring(len),
      i,
      add         = 0,
      pos         = len - 7,
      invalidCNPJ = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999'
      ],
      result
      ;

    if ( cnpj.length < 11 || $.inArray(cnpj, invalidCNPJ) !== -1 ) {
      return false;
    }

    for (i = len; i >= 1; i--) {
      add = add + parseInt(numbers.charAt(len - i)) * pos--;
      if (pos < 2) { pos = 9; }
    }

    result = (add % 11) < 2 ? 0 : 11 - (add % 11);
    if (result !== parseInt(digits.charAt(0))) {
      return false;
    }

    len = len + 1;
    numbers = cnpj.substring(0,len);
    add = 0;
    pos = len - 7;

    for (i = 13; i >= 1; i--) {
      add = add + parseInt(numbers.charAt(len - i)) * pos--;
      if (pos < 2) { pos = 9; }
    }

    result = (add % 11) < 2 ? 0 : 11 - (add % 11);
    if (result !== parseInt(digits.charAt(1))) {
      return false;
    }

    return true;
};

export default validateCNPJ;