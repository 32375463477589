import React, { useEffect, useCallback } from "react";
import PageHeader from "../components/PageHeader";
import PrimaryButton from "../components/PrimaryButton";

const Caso = (props) => {
  const previousPage = "/caso";
  const pageHeaderValue = "Kobana não é para o seu caso";

  const handleBackClick = useCallback(
    (e) => {
      props.history.push(previousPage);
    },
    [props.history]
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'onboarding.disqualified',
      });
    } else {
      console.log('dataLayer.push', {
        event: 'onboarding.disqualified',
      });
    }
    const handleEnter = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        handleBackClick(e);
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener("keydown", handleEnter);
    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [handleBackClick]);

  return (
    <div className="main-space-height">
      <div className="internal-space">
        <PageHeader value={pageHeaderValue} className="custom-padding-bottom" />
        <p>
          <strong>
            A Kobana não é um banco, não fazemos intermediação dos valores e não
            abrimos conta digital.
          </strong>
          .
        </p>
        <p>
          Nós não vamos substituir os serviços bancários. Nós simplificamos e
          levamos o consumo dos serviços oferecidos pelos bancos a outro nível,
          por meio de uma API única e uma plataforma com diversas
          funcionalidades que se conecta a mais de 30 bancos no Brasil.
        </p>
        <p>Para utilizar a Kobana, sua empresa vai precisar:</p>
        <ul>
          <li>Ter uma conta em banco tradicional ou banco digital;</li>
          <li>
            Pagar as tarifas por liquidação, transação e operação para o banco;
          </li>
          <li>
            Ter o custo adicional do uso da plataforma da Kobana, além das
            tarifas bancárias.
          </li>
        </ul>
        <p>
          A Kobana automatiza o fluxo financeiro da sua empresa, e oferece
          recursos avançados que agregam valor ao básico que os bancos oferecem.
        </p>
        <p>
          Se você é uma pequena empresa, possui baixo volume e está buscando uma
          conta digital, oferecida por intermediadores, temos algumas sugestões
          deste tipo de serviço abaixo:
        </p>
        <ul>
          <li>
            <a href="https://www.iugu.com/" target="_blank" rel="noreferrer">
              Iugu
            </a>
          </li>
          <li>
            <a href="https://www.asaas.com/" target="_blank" rel="noreferrer">
              Asaas
            </a>
          </li>
          <li>
            <a href="https://pagar.me/" target="_blank" rel="noreferrer">
              Pagar.me
            </a>
          </li>
          <li>
            <a
              href="https://www.paghiper.com/"
              target="_blank"
              rel="noreferrer"
            >
              PagHiper
            </a>
          </li>
          <li>
            <a href="https://vindi.com.br/" target="_blank" rel="noreferrer">
              Vindi
            </a>
          </li>
          <li>
            <a
              href="https://gerencianet.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              Gerencianet
            </a>
          </li>
          <li>
            <a href="https://superlogica.com/" target="_blank" rel="noreferrer">
              Superlógica
            </a>
          </li>
        </ul>
      </div>
      <div className="bottom-actions internal-space">
        <PrimaryButton value="Voltar" onClick={handleBackClick} />
      </div>
    </div>
  );
};

export default Caso;
