import React from 'react';

const UserQuestion = ({ onClick, value }) => {
  return (
    <div className="user-question" data-testid="user-question">
      <button onClick={onClick} id="open">
        <span>?</span>
        <div className="tooltip">{value}</div>
      </button>
    </div>
  );
};

export default UserQuestion;
