import React from 'react';

const FormInputField = ({
  label,
  name,
  register,
  errors,
  placeholder,
  type = 'text',
  rules = {},
  value,
  onChange,
  labelStyle,
  tabIndex,
  maxLength
}) => {
  return (
    <div className="single-input">
      <label style={labelStyle} htmlFor={name}>
        {label}
      </label>
      <br />
      <input
        className="input-pernonal-data"
        {...register(name, rules)}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        tabIndex={tabIndex}
        maxLength={maxLength}
        data-testid={`${name}-input`}
      />
      {errors[name] && (
        <span className="parsley-required parsley-error-messages input-error-form">
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

export default FormInputField;
