import React, { useEffect, useState } from 'react'
const bankList = [
  'ABC Brasil',
  'Agibank',
  'Ailos',
  'Alfa',
  'Arbi',
  'BMP',
  'BV',
  'Banco do Brasil',
  'Banco Industrial',
  'Banco Mercantil',
  'Banco Rendimento',
  'Banco Ribeirao Preto',
  'Bandepe',
  'Banese',
  'Banestes',
  'Banpará',
  'Banrisul',
  'BNB - Banco do Nordeste',
  'Bocom BBM',
  'Bradesco',
  'BS2',
  'BTG Empresas',
  'BTG Enterprise',
  'Caixa Econômica Federal',
  'Caruana',
  'Cifra',
  'Citibank',
  'Classico',
  'Cora',
  'Credisis',
  'Cresol',
  'Daycoval',
  'Digio',
  'Ewally',
  'Fator',
  'Fibra',
  'Genial',
  'Goldman Sachs do Brasil',
  'HSBC',
  'Inter',
  'Itaú',
  'LetsBank',
  'Mercantil do Brasil',
  'Modal',
  'Morgan Stanley',
  'Omni',
  'Ourinvest',
  'Pan',
  'Paraná Banco',
  'Paulista',
  'QI Tech',
  'Rodobens',
  'Safra',
  'Santander',
  'Semear',
  'Sicoob',
  'Sicredi',
  'Sofisa',
  'Starkbank',
  'Topázio',
  'Unicred do Brasil',
  'Uniprime Central',
  'Uniprime Paraná',
];

const TagInput = ({ selectedBanks, setSelectedBanks, setErrorMessage }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const normalizedInput = normalizeText(value);
    const newFilteredOptions = bankList.filter((bank) =>
      normalizeText(bank).startsWith(normalizedInput)
    );

    setFilteredOptions(newFilteredOptions);
    setShowSuggestions(newFilteredOptions.length > 0);

    setErrorMessage('');
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        e.stopPropagation();
        if (inputValue) {
          const normalizedInput = normalizeText(inputValue);
          const bestMatch = filteredOptions.find(
            (bank) =>
              normalizeText(bank).startsWith(normalizedInput) ||
              normalizeText(bank).substring(0, 4) ===
                normalizedInput.substring(0, 4)
          );

          if (bestMatch && !selectedBanks.includes(bestMatch)) {
            setSelectedBanks([...selectedBanks, bestMatch]);
          }
          setInputValue('');
          setShowSuggestions(false);
        }
      } else if (e.code === 'Backspace' && inputValue === '') {
        setSelectedBanks(selectedBanks.slice(0, -1));
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputValue, selectedBanks, setSelectedBanks, filteredOptions]);

  const handleRemoveBank = (bank) => {
    setSelectedBanks(selectedBanks.filter((b) => b !== bank));
  };

  const handleSelectBank = (bank) => {
    if (!selectedBanks.includes(bank)) {
      setSelectedBanks([...selectedBanks, bank]);
    }
    setInputValue('');
    setShowSuggestions(false);
  };

  return (
    <div className="tag-input">
      {selectedBanks.map((bank) => (
        <span key={bank} data-testid="selected-bank">
          {bank}
          <button
            type="button"
            onClick={() => handleRemoveBank(bank)}
            data-testid="remove-bank-button"
          >
            X
          </button>
        </span>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        maxLength={70}
        data-testid="bank-input"
      />
      {showSuggestions && (
        <div className="tag-input-option" data-testid="suggestions-list">
          {filteredOptions.map((bank) => (
            <div
              key={bank}
              onClick={() => handleSelectBank(bank)}
              className="tag-input-option-description"
              data-testid="suggestion-option"
            >
              {bank}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagInput;
