const maskCNPJ = (cnpj, setCnpj) => {
  let v = cnpj;
  v = v.replace(/\D/g, "")  //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, "$1.$2")  //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")  //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")  //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, "$1-$2")  //Coloca um hífen depois do bloco de quatro dígitos
  setCnpj(v);
}

export default maskCNPJ;
