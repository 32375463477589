import React from 'react';

const FormErrors = ({formErrors, ...props}) => {

  const errorMessages = (errorMessage) => {
    if(errorMessage){
      return (
        <p>{errorMessage}</p>
      )
    } else {
      return "";
    }
  }

  return (
    <div  {...props}>
      {errorMessages(formErrors)}
    </div>
  )

}

export default FormErrors;