import React, { useEffect, useState } from 'react';

const UserQuestionModal = (props) => {
  const { userQuestion, userQuestionText, closeModal, show } = props;
  const [display, setDisplay] = useState("display-none");

  useEffect(() => {
    if (show) {
      setDisplay("display-block");
    } else {
      setDisplay("display-none");
    }
  }, [show]);

  var userQuestionHtml = (userQuestionText) ? userQuestionText : props.children;

  return (
    <div id="overlay" className={display} onClick={closeModal}>
      <div id="popup" onClick={(e) => { e.stopPropagation(); }}>
        <button id="close" onClick={closeModal}></button>
        <div className="internal-space">
          <p><strong>{userQuestion}</strong></p>
          {userQuestionHtml}
        </div>
      </div>
    </div>
  );
}

export default UserQuestionModal;