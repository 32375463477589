const saveUtmToCookies = (setCookie) => {
  let urlParams;
  (window.onpopstate = function () {
      let match,
          pl     = /\+/g,  // Regex for replacing addition symbol with a space
          search = /([^&=]+)=?([^&]*)/g,
          decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
          query  = window.location.search.substring(1);

      urlParams = {};
      while ((match = search.exec(query)) !== null)
         urlParams[decode(match[1])] = decode(match[2]);
  })();
  const current = new Date();
  const next20Years = new Date();
  next20Years.setFullYear(current.getFullYear() + 20);
  let options = {
		expires: next20Years,
		path: '/',
		secure: false
	}
	if(process.env.NODE_ENV === 'production') {
		options['domain'] = '.kobana.com.br';
  }
  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'].forEach(function(item, i){
    if(urlParams.hasOwnProperty('clearUtmCookies')) {
      console.log('Clear cookie', item)
      setCookie(item, null, options);
    } else if(urlParams.hasOwnProperty(item)) {
      console.log('Cooking', item, 'with', urlParams[item])
      setCookie(item, urlParams[item], options);
    }
  });
}

export default saveUtmToCookies;