import $ from 'jquery';
import 'parsleyjs';
window.jQuery = $;
window.$ = $;

const validateForm = (setErrorMessage, errorMessage) => {
  $('#form').parsley().validate();
  if ($('#form').parsley().isValid()) {
    setErrorMessage();
    return true;
  } else {
    setErrorMessage(errorMessage);
    return false;
  }
}
export default validateForm;