import React from 'react';

const CountStepsBar = ({step, totalSteps}) => {

  const renderSteps = (step, totalSteps) => {
    const arr = new Array(parseInt(totalSteps));
    return [...arr.keys()].map((v, i) => {
      return (i < step) ? (<span key={i} className="step-done"></span>) : (<span key={i}></span>);
    });
  }
  return (
    <div>
      <div className="count-steps-block">
        <div className="count-steps">
          {renderSteps(step, totalSteps)}
        </div>
      </div>
    </div>
  );
}

export default CountStepsBar;