import React from 'react';
import Session from '../helpers/Session';

const Sucesso = (props) => {
  return (
    <div className="internal-space final-page">
      <h1>Parabéns, você está a um passo<br /> de conhecer a Kobana.</h1>
      <p>{Session.getItem('firstName')},</p>
      <p>Obrigado pelo seu interesse na Kobana.</p>
      <p>Recebemos as suas informações e iremos entrar em contato com você o mais breve possível.</p>
      <p>- A equipe Kobana</p>
    </div>
  )
}

export default Sucesso;
