import React from 'react';

const PageHeader = ({ value, className }) => {
  return (
    <h1 className={className} data-testid="page-header">
      {value}
    </h1>
  );
};

export default PageHeader;
