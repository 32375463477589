const maskPhone = (phone, setPhone) => {
  let v = phone;
  v = v.replace(/\D/g,"");             //Remove tudo o que não é dígito
  v = v.replace(/(\d)(\d{4})$/,"$1-$2");
  setPhone(v);
}

const maskDDD = (ddd, setDDD) => {
  let v = ddd;
  v = v.replace(/\D/g,"");             //Remove tudo o que não é dígito
  setDDD(v);
}

export { maskPhone, maskDDD };
