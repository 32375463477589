import React from 'react';

const RadioButton = ({
  label,
  name,
  id,
  checked,
  value,
  onChange,
  style,
  ...props
}) => {
  return (
    <div style={style}>
      <label className="radiobutton">
        {label}
        <input
          type="radio"
          name={name}
          id={id}
          checked={checked}
          value={value}
          onChange={onChange}
          {...props}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default RadioButton;
