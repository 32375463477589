import React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Caso from './containers/Caso';
import Intermediadores from './containers/Intermediadores';
import Interesse from './containers/Interesse';
import Sucesso from './containers/Sucesso';
import Sidebar from './components/Sidebar';
import CountStepsBar from './components/CountStepsBar';
import Transacoes from './containers/Transacoes';
import DadosPessoais from './containers/DadosPessoais';

function App() {

  const position = {
    '/': { progress: 0, step: 1 },
    '/caso': { progress: 1, step: 2 },
    '/interesse': { progress: 2, step: 2 },
    '/intermediadores': { progress: 2, step: 2 },
    '/transacoes': { progress: 3, step: 3 },
    '/sucesso': { progress: 4, step: 4 },
  };

  const currentStep = (page) => {
    return position[page].step;
  };

  const currentProgress = (page) => {
    return position[page].progress;
  };

  const renderContainer = (Container, props) => {
    const currentSidebarStep = currentStep(props.location.pathname);
    return (
      <div>
        <div className="sidebar-fixed">
          <Sidebar
            sidebarStep={currentSidebarStep}
            customClassComponent={currentSidebarStep === 3 && 'custom-height'}
            customClassImage={currentSidebarStep === 3 && 'custom-bottom'}
          />
        </div>
        <div className="main-content">
          <CountStepsBar
            step={currentProgress(props.location.pathname)}
            totalSteps={Object.keys(position).length - 2}
          />
          <Container {...props} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div className="App">
          <Switch>
            <Route
              path="/caso"
              render={(props) => renderContainer(Caso, props)}
            />
            <Route
              path="/interesse"
              render={(props) => renderContainer(Interesse, props)}
            />
            <Route
              path="/intermediadores"
              render={(props) => renderContainer(Intermediadores, props)}
            />
            <Route
              path="/transacoes"
              render={(props) => renderContainer(Transacoes, props)}
            />
            <Route
              path="/sucesso"
              render={(props) => renderContainer(Sucesso, props)}
            />
            <Route
              path="/"
              render={(props) => renderContainer(DadosPessoais, props)}
            />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
