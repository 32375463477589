import React from 'react';
import { useCookies } from 'react-cookie';


const Sidebar = ({
  sidebarStep,
  customClassComponent,
  customClassImage,
}) => {
  const [cookies] = useCookies(['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid']);

  const highlightCurrent = (currentStep) => {
    if (currentStep === sidebarStep) {
      return 'sidebar-active';
    } else {
      return '';
    }
  };

  return (
    <div className={`sidebar ${customClassComponent}`}>
      { !(cookies['utm_source'] === 'event' && (cookies['utm_medium'] === 'self-form' || cookies['utm_medium'] === 'sales-form')) && (
      <ul className="sidebar-steps">
        <li className={highlightCurrent(1)}>
          <span>Dados da Empresa</span>
        </li>
        <li className={highlightCurrent(2)}>
          <span>Necessidades</span>
        </li>
        <li className={highlightCurrent(3)}>
          <span>Volume Mensal</span>
        </li>
        <li className={highlightCurrent(4)}>
          <span>Conclusão</span>
        </li>
      </ul>
      )}
      <a href="https://www.boletosimples.com.br/">
        <img
          src="images/logo.png"
          alt="Boleto Simples"
          className={`${customClassImage}`}
        />
      </a>
    </div>
  );
};

export default Sidebar;
