import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import PageHeader from "../components/PageHeader";
import PrimaryButton from "../components/PrimaryButton";
import Session from "../helpers/Session";
import { maskDDD, maskPhone } from "../helpers/maskPhone";
import maskCNPJ from "../helpers/maskCNPJ";
import saveUtmToCookies from "../helpers/utmTracking";
import UserQuestion from "../components/UserQuestion";
import UserQuestionModal from "../components/UserQuestionModal";
import FormInputField from "../components/FormInputField";
import validateCNPJ from "../helpers/validateCNPJ";

const DadosPessoais = (props) => {
  const [cookies, setCookie] = useCookies([
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "gclid",
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const userQuestion = "Posso usar Kobana como pessoa física?";
  const userQuestionText = `Não, as conexões com os bancos implementadas na Kobana são quase que exclusivamente oferecidas para empresas.
     Além disso, a interface e a API da Kobana foram feitas para gerenciar altos volumes de transações. Por isso, a Kobana não atende pessoa física.`;
  const [showModal, setShowModal] = useState(false);
  const [ddd, setDdd] = useState(Session.getItem("ddd") || "");
  const [phone, setPhone] = useState(Session.getItem("phone") || "");
  const [cnpj, setCnpj] = useState(Session.getItem("cnpj") || "");
  const [jobTitle, setJobTitle] = useState(Session.getItem("jobTitle") || "");
  const [otherJobTitle, setOtherJobTitle] = useState(
    jobTitle === "Outro" ? Session.getItem("otherJobTitle") : "",
  );
  const [organizationType, setOrganizationType] = useState(
    Session.getItem("organizationType") || "Banco",
  );
  const [jobField, setJobField] = useState(Session.getItem("jobField") || "");
  const [otherJobField, setOtherJobField] = useState(
    jobField === "Outro" ? Session.getItem("otherJobField") : "",
  );

  const [formData, setFormData] = useState({
    firstName: Session.getItem("firstName") || "",
    lastName: Session.getItem("lastName") || "",
    company: Session.getItem("company") || "",
    email: Session.getItem("email") || "",
    observation: Session.getItem("observation") || "",
  });
  const [pageConfig, setPageConfig] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCNPJChange = (e) => {
    maskCNPJ(e.target.value, setCnpj);
    clearErrors("cnpj");
  };

  const handlePhoneChange = (e) => {
    maskPhone(e.target.value, setPhone);
    clearErrors("phone");
  };

  const handleDDDChange = (e) => {
    maskDDD(e.target.value, setDdd);
    clearErrors("ddd");
  };

  const handleOrganizationTypeChange = (e) => {
    const checked = e.target.checked;
    if (!checked) {
      setOrganizationType("Empresa");
    } else {
      setOrganizationType("Banco");
    }
    clearErrors("organizationType");
  };

  const handleJobFieldChange = (e) => {
    const newJobField = e.target.value;
    setJobField(newJobField);
    if (newJobField !== "Outra") {
      setOtherJobField("");
      localStorage.removeItem("otherJobField");

      clearErrors("jobField");
    }
  };

  const handleOtherJobFieldChange = (e) => {
    const newOtherJobField = e.target.value;
    setOtherJobField(newOtherJobField);
    setValue("jobField", newOtherJobField);

    if (newOtherJobField) {
      setJobField("Outra");
      localStorage.setItem("otherJobField", newOtherJobField);
    }

    clearErrors("otherJobField");
  };

  const handleJobTitleChange = (e) => {
    const newJobTitle = e.target.value;
    setJobTitle(newJobTitle);
    setValue("jobTitle", newJobTitle, { shouldValidate: true });
    if (newJobTitle !== "Outro") {
      setOtherJobTitle("");
      localStorage.removeItem("otherJobTitle");
    }
    clearErrors("jobTitle");
  };

  const handleOtherJobTitleChange = (e) => {
    const newOtherJobTitle = e.target.value;
    setOtherJobTitle(newOtherJobTitle);
    if (newOtherJobTitle) {
      setJobTitle("Outro");
      setValue("jobTitle", "Outro", { shouldValidate: true });
      localStorage.setItem("otherJobTitle", newOtherJobTitle);
    }
    clearErrors("otherJobTitle");
  };

  const openModalClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModalClick = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  const onSubmit = useCallback(() => {
    Object.entries(formData).forEach(([key, value]) => {
      Session.setItem(key, value);
    });
    Session.setItem("ddd", ddd);
    Session.setItem("phone", phone);
    Session.setItem("cnpj", cnpj);
    Session.setItem("organizationType", organizationType);
    Session.setItem("jobTitle", jobTitle);
    Session.setItem("jobField", jobField);
    if (jobTitle === "Outro") {
      Session.setItem("otherJobTitle", otherJobTitle);
    }
    if (jobField === "Outra") {
      Session.setItem("otherJobField", otherJobField);
    }

    let completeJob = jobTitle === "Outro" ? otherJobTitle : jobTitle;
    const completeJobField = jobField === "Outra" ? otherJobField : jobField;
    if (completeJobField !== "") {
      completeJob += " - " + completeJobField;
    }

    const sendData = {
      CNPJ: Session.getItem("cnpj"),
      Empresa: Session.getItem("company"),
      Nome: Session.getItem("firstName"),
      Sobrenome: Session.getItem("lastName"),
      Email: Session.getItem("email"),
      DDD: Session.getItem("ddd"),
      Telefone: Session.getItem("phone"),
      Tipo_de_Organizacao: Session.getItem("organizationType"),
      Observacoes: Session.getItem("observation"),
      Cargo: completeJob,
      utm_source: cookies["utm_source"],
      utm_medium: cookies["utm_medium"],
      utm_campaign: cookies["utm_campaign"],
      utm_content: cookies["utm_content"],
      utm_term: cookies["utm_term"],
      gclid: cookies["gclid"],
    };

    let zapierUrl =
      cookies["utm_source"] === "event"
        ? "https://hooks.zapier.com/hooks/catch/509690/307vqyi/"
        : "https://hooks.zapier.com/hooks/catch/509690/3asrvmt/";

    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "onboarding.started",
      });
      fetch(zapierUrl, {
        method: "POST",
        body: JSON.stringify(sendData),
      }).then((response) => response.json());
    } else {
      console.log("dataLayer.push", {
        event: "onboarding.started",
      });
      console.log("Submit data to Zapier", {
        url: zapierUrl,
        data: sendData,
      });
    }
    if (
      cookies["utm_source"] === "event" &&
      cookies["utm_medium"] === "sales-form"
    ) {
      Object.entries(formData).forEach(([key, value]) => {
        Session.setItem(key, "");
      });
      Session.setItem("ddd", "");
      Session.setItem("phone", "");
      Session.setItem("cnpj", "");
      Session.setItem("jobTitle", "");
      Session.setItem("otherJobTitle", "");
      Session.setItem("organizationType", "");
      Session.setItem("jobField", "");
      Session.setItem("otherJobField", "");
      Session.setItem("observation", "");
      setDdd("");
      setPhone("");
      setCnpj("");
      setJobTitle("");
      setOrganizationType("Banco");
      setJobField("");
      setOtherJobField("");
      setFormData({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        observation: "",
      });
    } else {
      props.history.push(pageConfig["nextPage"]);
    }
  }, [
    formData,
    ddd,
    phone,
    cnpj,
    organizationType,
    jobField,
    otherJobField,
    jobTitle,
    otherJobTitle,
    props.history,
    cookies,
    pageConfig,
  ]);

  useEffect(() => {
    saveUtmToCookies(setCookie);
    if (
      cookies["utm_source"] === "event" &&
      cookies["utm_medium"] === "sales-form"
    ) {
      setPageConfig({
        header: `Novo lead no evento ${cookies["utm_campaign"]} por ${cookies["utm_content"]}`,
        mainText: "",
        askCnpj: false,
        askJobField: true,
        askObservation: true,
        askOrganizationType: true,
        cnpjRequired: false,
        nextPage: "/sucesso",
      });
    } else if (
      cookies["utm_source"] === "event" &&
      cookies["utm_medium"] === "self-form"
    ) {
      setPageConfig({
        header:
          "Agende uma consultoria com um de nossos especialistas de automação financeira",
        mainText:
          "Vamos entender profundamente o contexto da sua empresa para propor a melhor solução de escalabilidade nas operações bancárias, trazendo mais agilidade e segurança para o seu processo atual.",
        askCnpj: false,
        askJobField: false,
        askObservation: false,
        askOrganizationType: false,
        nextPage: "/sucesso",
      });
    } else {
      setPageConfig({
        header:
          "A Kobana está pronta pra te conhecer e ajudar no seu processo de automação financeira",
        mainText:
          "Com estas informações o nosso time de especialistas irá entrar em contato para entender melhor os desafios da sua empresa. Temos mais de 10 anos de experiência em automação de operações bancárias e integração de sistemas financeiros com mais de 30 bancos no Brasil.",
        askCnpj: true,
        cnpjRequired: true,
        askJobField: false,
        askObservation: false,
        askOrganizationType: false,
        nextPage: "/caso",
      });
    }
  }, [cookies, setPageConfig, setCookie]);

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        handleSubmit(onSubmit)();
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener("keydown", handleEnter);
    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [handleSubmit, onSubmit, register, setValue, setCookie]);

  return (
    <div className="main-space-height">
      <div className="main-space">
        <PageHeader
          className="custom-padding-bottom"
          value={pageConfig["header"]}
        />
        <p>{pageConfig["mainText"]}</p>
        <form
          id="form"
          className="custom-padding-top"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="custom-form">
            <div className="single-input">
              <label htmlFor="firstName">Nome*</label>
              {errors.firstName && (
                <span className="parsley-required parsley-error-messages input-error-form">
                  {errors.firstName.message}
                </span>
              )}
              <br />
              <input
                className="input-pernonal-data"
                {...register("firstName", {
                  required: "O campo nome é obrigatório",
                })}
                placeholder="Nome"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  handleInputChange(e);
                  clearErrors("firstName");
                }}
                tabIndex="1"
                autoFocus={true}
              />
            </div>
            <FormInputField
              label="Sobrenome*"
              name="lastName"
              register={register}
              rules={{
                required: "O campo sobrenome é obrigatório",
              }}
              errors={errors}
              placeholder="Sobrenome"
              value={formData.lastName}
              onChange={(e) => {
                handleInputChange(e);
                clearErrors("lastName");
              }}
              tabIndex="2"
            />
          </div>
          <div className="custom-form">
            <div className="single-input first-name container-phone">
              <div className="input-mini">
                <label htmlFor="ddd">DDD*</label>
                {errors.ddd && (
                  <span className="parsley-required parsley-error-messages input-error-form">
                    {errors.ddd.message}
                  </span>
                )}
                <br />
                <input
                  type="tel"
                  className="input-pernonal-data"
                  {...register("ddd", {
                    required: "Obrigatório",
                  })}
                  name="ddd"
                  maxLength="2"
                  placeholder="__"
                  value={ddd}
                  onChange={handleDDDChange}
                  tabIndex="3"
                />
              </div>
              <div className="input-midi">
                <label htmlFor="phone">Celular*</label>
                {errors.phone && (
                  <span className="parsley-required parsley-error-messages input-error-form">
                    {errors.phone.message}
                  </span>
                )}
                <br />
                <input
                  type="tel"
                  className="input-pernonal-data"
                  {...register("phone", {
                    required: "O campo celular é obrigatório",
                  })}
                  name="phone"
                  maxLength="10"
                  placeholder="_____-____"
                  value={phone}
                  onChange={handlePhoneChange}
                  tabIndex="4"
                />
              </div>
            </div>

            <FormInputField
              label="Seu e-mail corporativo*"
              name="email"
              register={register}
              rules={{
                required: "O campo e-mail é obrigatório",
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,12}$/,
                  message: "Insira um email válido",
                },
              }}
              errors={errors}
              placeholder="E-mail corporativo"
              value={formData.email}
              onChange={(e) => {
                handleInputChange(e);
                clearErrors("email");
              }}
              type="email"
              tabIndex="5"
            />
          </div>
          <div className="custom-form">
            <FormInputField
              labelStyle={{
                display: "inline-table",
                height: "32px",
                margin: "0",
              }}
              label={
                <div className="custom-label">
                  Empresa*
                  <UserQuestion
                    data-testid="user-question"
                    onClick={openModalClick}
                    value={userQuestion}
                  />
                  <UserQuestionModal
                    show={showModal}
                    userQuestion={userQuestion}
                    userQuestionText={userQuestionText}
                    closeModal={closeModalClick}
                  />
                </div>
              }
              name="company"
              register={register}
              rules={{
                required: "O campo empresa é obrigatório",
              }}
              errors={errors}
              placeholder="Nome da Empresa"
              value={formData.company}
              onChange={(e) => {
                handleInputChange(e);
                clearErrors("company");
              }}
              tabIndex="6"
            />
            <div className="single-input">
              <label htmlFor="jobTitle">Seu Cargo</label>
              <br />
              <div style={{ minWidth: "378px" }}>
                <div className="single-input">
                  <select
                    className="select-pernonal-data"
                    {...register("jobTitle", {
                      required: "Cargo é obrigatório",
                    })}
                    name="jobTitle"
                    value={jobTitle}
                    onChange={handleJobTitleChange}
                    data-testid="select-form"
                    tabIndex="7"
                  >
                    <option value="" disabled>
                      Escolha seu cargo
                    </option>
                    <option value="Fundador / Sócio"> Fundador / Sócio</option>
                    <option value="CEO / Presidente">CEO / Presidente</option>
                    <option value="Diretor / C-Level">Diretor / C-Level</option>
                    <option value="Gerente / Coordenador / Head">
                      Gerente / Coordenador / Head
                    </option>
                    <option value="Analista">Analista</option>
                    <option value="Outro">Outro</option>
                  </select>
                </div>
                {jobTitle === "Outro" && (
                  <div>
                    <input
                      {...register("otherJobTitle", {
                        required: "É obrigatório",
                      })}
                      className="input-pernonal-data"
                      type="text"
                      name="otherJobTitle"
                      placeholder="Qual?"
                      value={otherJobTitle}
                      onChange={handleOtherJobTitleChange}
                      required={jobTitle === "Outro"}
                      tabIndex="9"
                    />
                  </div>
                )}
                {errors.jobTitle && (
                  <span className="parsley-required parsley-error-messages input-error-form job-error-form">
                    {errors.jobTitle.message}
                  </span>
                )}
                {errors.otherJobTitle && (
                  <span className="parsley-required parsley-error-messages input-error-form job-error-form">
                    {errors.otherJobTitle.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="custom-form">
            {pageConfig["askJobField"] && (
              <div className="single-input">
                <label htmlFor="jobField">Area de Atuacao</label>
                <br />
                <div style={{ minWidth: "378px" }}>
                  <div className="single-input">
                    <select
                      className="select-pernonal-data"
                      {...register("jobField", {
                        required: "Area de atuacao é obrigatório",
                      })}
                      name="jobField"
                      value={jobField}
                      onChange={handleJobFieldChange}
                      data-testid="select-form"
                      tabIndex="7"
                    >
                      <option value="" disabled>
                        Escolha a area de atuacao.
                      </option>
                      <option value="Financeiro">Financeiro</option>
                      <option value="Tesouraria">Tesouraria</option>
                      <option value="Contas a Pagar">Contas a Pagar</option>
                      <option value="Contas a Receber">Contas a Receber</option>
                      <option value="TI/Desenvolvimento">
                        TI/Desenvolvimento
                      </option>
                      <option value="Outra">Outra</option>
                    </select>
                    {jobField === "Outra" && (
                      <div>
                        <input
                          {...register("otherJobField", {
                            required: "É obrigatório",
                          })}
                          className="input-pernonal-data"
                          type="text"
                          name="otherJobField"
                          placeholder="Qual?"
                          value={otherJobField}
                          onChange={handleOtherJobFieldChange}
                          required={jobField === "Outra"}
                          tabIndex="9"
                        />
                      </div>
                    )}
                  </div>
                  {errors.organizationType && (
                    <span className="parsley-required parsley-error-messages input-error-form job-error-form">
                      {errors.organizationType.message}
                    </span>
                  )}

                  {pageConfig["askOrganizationType"] && (
                    <div className="single-input">
                      <div style={{ minWidth: "378px" }}>
                        <div className="single-input radio-option">
                          <label htmlFor="banco">Banco</label>
                          <input
                            type="checkbox"
                            id="banco"
                            className="radio-personal-data"
                            {...register("organizationType")}
                            name="organizationType"
                            value="Banco"
                            checked={organizationType === "Banco"}
                            onChange={handleOrganizationTypeChange}
                            data-testid="radio-banco"
                            tabIndex="7"
                          />
                        </div>
                        {errors.organizationType && (
                          <span className="parsley-required parsley-error-messages input-error-form job-error-form">
                            {errors.organizationType.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="custom-form">
            {pageConfig["askCnpj"] && (
              <FormInputField
                labelStyle={{
                  display: "inline-table",
                  height: "32px",
                  margin: "0",
                }}
                label={
                  pageConfig["cnpjRequired"] ? (
                    <div className="custom-label">CNPJ da Empresa*</div>
                  ) : (
                    <div className="custom-label">CNPJ da Empresa</div>
                  )
                }
                name="cnpj"
                register={register}
                rules={
                  pageConfig["cnpjRequired"]
                    ? {
                        required: "O campo CNPJ é obrigatório",
                        validate: (_value, _formValues) =>
                          validateCNPJ(cnpj) || "CNPJ inválido",
                      }
                    : {}
                }
                errors={errors}
                maxLength="18"
                placeholder="__.___.___./____-__"
                value={cnpj}
                onChange={handleCNPJChange}
                tabIndex="10"
              />
            )}

            {pageConfig["askObservation"] && (
              <FormInputField
                labelStyle={{
                  display: "inline-table",
                  height: "32px",
                  margin: "0",
                }}
                label={<div className="custom-label">Observacoes</div>}
                name="observation"
                register={register}
                rules={{}}
                errors={errors}
                placeholder="Observacoes"
                value={formData.observation}
                onChange={(e) => {
                  handleInputChange(e);
                  clearErrors("observation");
                }}
                tabIndex="6"
              />
            )}
            <div className="submit-button">
              <PrimaryButton onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
          <button type="submit" className="display-none" />
        </form>
      </div>
    </div>
  );
};
export default DadosPessoais;
