import React, { useState, useEffect, useCallback } from 'react';
import PageHeader from '../components/PageHeader';
import PrimaryButton from '../components/PrimaryButton';
import Button from '../components/Button';
import Session from '../helpers/Session';
import RadioButton from '../components/RadioButton';
import validateForm from '../helpers/validateForm';
import FormErrors from '../components/FormErrors';

const Interesse = (props) => {
  const previousPage = '/caso';
  const nextPage = '/transacoes';
  const nextPageNotCase = '/intermediadores';
  const pageHeaderValue =
    'Nos conte um pouco mais da sua necessidade neste momento?';
  const [goal, setGoal] = useState(Session.getItem('goal'));
  const [otherGoal, setOtherGoal] = useState(Session.getItem('otherGoal'));

  const [errorMessage, setErrorMessage] = useState('');
  const [otherRequired, setOtherRequired] = useState(
    Session.getItem('otherGoal') ? true : false
  );

  const handleSubmit = useCallback(
    (e) => {
      if (e) e.preventDefault();
      const errorMsg = otherRequired
        ? 'Preencha com o outro interesse'
        : 'Interesse é obrigatório';
      if (validateForm(setErrorMessage, errorMsg)) {
        Session.setItem('goal', goal);
        Session.setItem('otherGoal', otherGoal);
        if (goal === 'reduceCost') {
          props.history.push(nextPageNotCase);
        } else {
          props.history.push(nextPage);
        }
      }
    },
    [goal, otherGoal, otherRequired, props.history]
  );

  const handleRadioChange = (e) => {
    setGoal(e.target.value);
    if (e.target.value === 'Outro') {
      setOtherRequired(true);
      document.getElementById('otherGoal').focus();
    } else {
      setOtherRequired(false);
      setOtherGoal('');
    }
    setErrorMessage();
    // validateForm(setErrorMessage, "Interesse é obrigatório");
  };

  const handleOtherChange = (e) => {
    setOtherGoal(e.target.value);
  };

  const focusOnOther = (e) => {
    setGoal('Outro');
  };

  const handleBackClick = (e) => {
    props.history.push(previousPage);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleEnter = (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleSubmit();
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [handleSubmit]);

  return (
    <div className="main-space-height">
      <div className="internal-space">
        <PageHeader className="custom-padding-bottom" value={pageHeaderValue} />
        <form
          id="form"
          className="onboarding-form"
          data-parsley-validate
          onSubmit={handleSubmit}
          data-parsley-errors-messages-disabled
        >
          <FormErrors
            formErrors={errorMessage}
            className="parsley-error-messages"
          />
          <div className="single-input" style={{width:'100%'}}>
            <RadioButton
              label="Automatizar operações bancárias no sistema de gestão (ERP) que minha empresa utiliza"
              value="Automatizar operações bancárias no sistema de gestão (ERP) que minha empresa utiliza"
              name="goal"
              id="goal"
              onChange={handleRadioChange}
              checked={
                goal ===
                'Automatizar operações bancárias no sistema de gestão (ERP) que minha empresa utiliza'
              }
              required
              data-parsley-errors-messages-disabled="true"
            />
            <RadioButton
              label="Automatizar tráfego dos arquivos de remessa e retorno com o banco"
              value="Automatizar tráfego dos arquivos de remessa e retorno com o banco"
              name="goal"
              id="goal"
              onChange={handleRadioChange}
              checked={
                goal ===
                'Automatizar tráfego dos arquivos de remessa e retorno com o banco'
              }
            />
            <RadioButton
              label="Automatizar régua de comunicação e disparos de e-mail e SMS aos meus clientes"
              value="Automatizar régua de comunicação e disparos de e-mail e SMS aos meus clientes"
              name="goal"
              id="goal"
              onChange={handleRadioChange}
              checked={
                goal ===
                'Automatizar régua de comunicação e disparos de e-mail e SMS aos meus clientes'
              }
            />
            <RadioButton
              label="Ter um portal onde meus clientes possam visualizar as cobranças que eu enviei pra eles"
              value="Ter um portal onde meus clientes possam visualizar as cobranças"
              name="goal"
              id="goal"
              onChange={handleRadioChange}
              checked={
                goal ===
                'Ter um portal onde meus clientes possam visualizar as cobranças'
              }
            />
            <RadioButton
              label="Reduzir os custos pagos em taxas para o meu banco pela liquidação das transações"
              value="reduceCost"
              name="goal"
              id="goal"
              onChange={handleRadioChange}
              checked={goal === 'reduceCost'}
            />

            <label className="radiobutton">
              Outro
              <input
                type="radio"
                name="goal"
                id="goal"
                value="Outro"
                onChange={handleRadioChange}
                checked={goal === 'Outro'}
              />
              <span className="checkmark"></span>
              <input
                style={{ width: '50%' }}
                type="text"
                name="otherGoal"
                id="otherGoal"
                placeholder="Qual?"
                onFocus={focusOnOther}
                value={otherGoal}
                onChange={handleOtherChange}
                required={otherRequired}
              />
            </label>
          </div>
          <button type="submit" className="display-none" />
        </form>
      </div>
      <div className="bottom-actions internal-space">
        <PrimaryButton onClick={handleSubmit} />
        <Button value="Voltar" onClick={handleBackClick} />
      </div>
    </div>
  );
};

export default Interesse;
