const Session = (function () {
  const getData = () => {
    return JSON.parse(localStorage.getItem('OnboardingData')) || {};
  };

  const getItem = (id) => {
    const data = getData();
    return data[id] ? data[id] : '';
  };

  const setItem = (id, value) => {
    let data = getData();
    data[id] = value;
    localStorage.setItem('OnboardingData', JSON.stringify(data));
  };

  const removeItem = (id) => {
    let data = getData();
    delete data[id];
    localStorage.setItem('OnboardingData', JSON.stringify(data));
  };

  // setCurrentPage
  // const setCurrentPage = (page) => {
  //   localStorage.setItem('CurrentPage', page);
  // }

  // getCurrentPage
  // const getCurrentPage = () => {
  //   return localStorage.getItem('CurrentPage') || '/cnpj';
  // }

  return {
    getData: getData,
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem,
  };
})();

export default Session;
