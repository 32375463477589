import React from 'react';

const Checkbox = ({
  title,
  name,
  id,
  checked,
  onChange,
  ...props
}) => {
  return (
    <div>
      <label className="checkbox" data-testid={`label-${name}`}>
        <h4>{title}</h4>
        <input
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
          onChange={onChange}
          {...props}
          data-testid={`checkbox-${name}`}
        />
        <span className="checkmark-cb"></span>
      </label>
    </div>
  );
};

export default Checkbox;
