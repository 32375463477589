import React from "react";

const PrimaryButton = ({
  buttonClass = "btn-active",
  nextPage,
  onClick,
  value = "Seguir",
  children,
}) => {
  return (
    <div className="btn-continue-position">
      <a
        href={nextPage}
        className={buttonClass + " sbmt-container"}
        onClick={onClick}
        data-testid="primary-button"
      >
        {children} {value}
      </a>
      <span>ou clique Enter</span>
    </div>
  );
};

export default PrimaryButton;
