import React, { useState, useEffect, useCallback } from 'react';
import PageHeader from '../components/PageHeader';
import PrimaryButton from '../components/PrimaryButton';
import Button from '../components/Button';
import Session from '../helpers/Session';
import RadioButton from '../components/RadioButton';
import validateForm from '../helpers/validateForm';
import FormErrors from '../components/FormErrors';
import { useCookies } from 'react-cookie';

const Caso = (props) => {
  const previousPage = '/';
  const pageHeaderValue = 'Qual opção se encaixa melhor com o seu caso?';
  const [useCase, setUseCase] = useState(Session.getItem('useCase'));
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      if (e) e.preventDefault();
      if (validateForm(setErrorMessage, 'Caso de uso é obrigatório')) {
        Session.setItem('useCase', useCase);
        if (useCase === 'empresa') {
          props.history.push('/interesse');
        } else if (useCase === 'software-house') {
          props.history.push('/transacoes');
        } else {
          props.history.push('/intermediadores');
        }
      }
    },
    [useCase, props.history]
  );

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setUseCase(value);
    setErrorMessage('');

    if (value === 'empresa' || value === 'software-house') {
      Session.setItem('case', value);
    }
  };

  const handleBackClick = (_e) => {
    props.history.push(previousPage);
  };

  const [cookies] = useCookies();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleEnter = (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleSubmit(e);
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [cookies, handleSubmit]);

  return (
    <div className="main-space-height">
      <div className="internal-space">
        <PageHeader className="custom-padding-bottom" value={pageHeaderValue} />
        <form
          id="form"
          className="onboarding-form"
          data-parsley-validate
          onSubmit={handleSubmit}
          data-parsley-errors-messages-disabled
        >
          <FormErrors
            formErrors={errorMessage}
            className="parsley-error-messages"
          />
          <div className="asingle-input">
            <RadioButton
              label="Empresa buscando automatizar as suas operações financeiras"
              name="segment"
              id="segment"
              value="empresa"
              onChange={handleRadioChange}
              checked={useCase === 'empresa'}
              required
              data-parsley-required-message="Caso é obrigatório"
              data-parsley-errors-messages-disabled="true"
            />
            <div className="radiobutton-help ">
              Estou em busca de uma plataforma que se conecte às contas
              bancárias (de bancos tradicionais ou digitais) que minha empresa
              já usa para facilitar e automatizar a conciliação do movimento
              bancário, os fluxos de emissão de cobranças (boletos, pix), de
              pagamentos (boleto, utility, impostos), de transferências (pix,
              ted) usando as APIs e arquivos CNABs que os bancos oferecem
            </div>
            <br />
            <RadioButton
              label="Empresa buscando substituir o serviço do meu banco atual"
              name="segment"
              id="segment"
              value="substitute"
              onChange={handleRadioChange}
              checked={useCase === 'substitute'}
            />
            <div className="radiobutton-help">
              Estou em busca de um serviço de conta digital que substitua os
              serviços oferecidos pelos bancos tradicionais, tais como
              liquidação de boletos, liquidação de pix, transferência e
              pagamentos, com recursos, taxas e tarifas melhores dos que os
              meus bancos atuais oferecem.
            </div>
            <br />
            <RadioButton
              label="Software house buscando integrar as contas dos meus usuários"
              name="segment"
              id="segment"
              value="software-house"
              onChange={handleRadioChange}
              checked={useCase === 'software-house'}
              required
              data-parsley-required-message="Caso é obrigatório"
              data-parsley-errors-messages-disabled="true"
            />
            <div className="radiobutton-help">
              Sou uma empresa de software que oferece um sistema de gestão
              (ERP) e estou em busca de solução de integração das contas dos
              meus usuários para que eu não precise desenvolver e manter todas
              as integrações com todos os bancos e possa focar no
              desenvolvimento do meu produto.
            </div>
            <br />
          </div>
          <button type="submit" className="display-none" />
        </form>
      </div>
      <div className="bottom-actions internal-space">
        <PrimaryButton onClick={handleSubmit} />
        <Button value="Voltar" onClick={handleBackClick} />
      </div>
    </div>
  );
};

export default Caso;
